<template>
  <v-app-bar 
    app
    flat
    color="white"
    :class="{ 'mobile-only': view.mobile }"
  >
    <v-fade-transition mode="out-in">
      <v-btn
        v-if="!main.back"
        icon
        color="accent"
        readonly
      >
        <icon-base
          width="151"
          height="163"
          icon-name="hive"
          class="hive"
        >
          <brand-symbol />
        </icon-base>
      </v-btn>
      <v-btn 
        v-else
        color="accent"
        icon
        @click="navigateBack"
      >
        <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
      </v-btn>
    </v-fade-transition>
    <v-sheet
      tile
      class="entities ml-2"
    >
      <v-btn 
        text
        height="40px"
        color="grey darken-1"
        class="btn-logo py-2"
      >
        <img
          v-if="hasLogo"
          ref="logo"
          :src="setLogo"
          class="logo"
        >
        <h2 
          v-else
          class="subtitle-1"
        >
          {{ setTitle }}
        </h2>
      </v-btn>
    </v-sheet>

    <!-- <v-toolbar-title 
      class="app-title pr-0 pa-2 pl-3"
      @click="toggleMenu(true)"
    >
      {{ view.title }}
    </v-toolbar-title> -->

    <v-spacer />

    <v-tabs 
      centered
      hide-slider
      color="accent"
      class="main-nav"
    >
      <v-tab 
        to="/auditor/"
        exact
      >
        <span v-if="breakpoint('xs')">
          Dashboard
        </span>
        <v-icon 
          v-else
          class=""
        >{{ icons.dashboard }}
        </v-icon>
      </v-tab>
      <v-tab 
        to="/auditor/buzzers"
        exact
      >
        <span v-if="breakpoint('xs')">
          Buzzers
        </span>
        <v-icon 
          v-else
          class=""
        >{{ icons.buzzers }}
        </v-icon>
      </v-tab>
    </v-tabs>

    <v-spacer />

    <!-- <v-text-field
        v-model="input.search.value"
        :loading="input.search.loading"
        :disabled="input.search.disabled"
        :error-messages="input.search.messages"
        :prepend-inner-icon="icons.mdiMagnify"
        clearable
        class="px-4"
        name="global-search"
        ref="search"
        placeholder="Buscar por Buzzer, Motorista, Campanhas..."
        @keyup.esc="input.search.value=''"
      /> -->
    <!-- <v-fade-transition>
      <v-progress-circular
        v-if="loading.toggle"
        :size="24"
        :width="3"
        color="grey lighten-2"
        class="mt-3"
        indeterminate
      />
    </v-fade-transition> -->

    <v-menu
      transition="slide-y-transition"
      offset-y
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
        >
          <v-avatar color="grey" size="36">
            <span class="white--text button">{{ user.username.substring(0,2) }}</span>
          </v-avatar>
          <!-- <v-icon v-text="icons.mdiDotsVertical" /> -->
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          @click="getout"
        >
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- <span v-show="fab.toggle && minScroll">
      <v-fab-transition>
        <v-btn
          color="white"
          absolute
          bottom
          right
          fab
          elevation="2"
          class="btn-new primary--text ma-4"
          :to="fab.to"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </v-fab-transition>
    </span> -->
  </v-app-bar>
</template>

<style>

  #app .v-app-bar {
    /* box-shadow: 0px 4px 4px -11px rgba(0, 0, 0, 0.2), 0px 5px 5px -10px rgba(0, 0, 0, 0.14), 0px 6px 10px -6px rgba(0, 0, 0, 0.12); */
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  @media (orientation: landscape) {
    .v-app-bar.mobile-only {
      transform: translateY(-100%) !important;
      /* display: none; */
    }
  }
  @media (orientation: portrait) {
    .v-app-bar.mobile-only {
      transform: translateY(0) !important;
      /* display: block; */
    }
  }

  .v-app-bar .v-tab {
    min-width: auto;
  }

  .v-toolbar__content {
    max-height: 120px;
    width: 100%;
  }
  /* .v-app-bar {
    background: var(--mobees-black) !important;
    transform: translateY(calc(16px - 100%)) !important;

  }
  .v-app-bar.toggle {
    transform: translateY(0) !important;
  } */

  .app-title {
    max-width: 72%;
    z-index: 1;
    transition: transform .15s ease-out;
    will-change: transform;
    transform: 0;
  }
  .app-title.extended {
    max-width: 80%;
    transform: translateX(-48px);
  }

  .v-app-bar .hive {
    height: 56px;
    /* animation: buzz 5s 2s cubic-bezier(0.990, 0.005, 0.100, 1.005) infinite; */
  }

  @-webkit-keyframes buzz {
    0%{ 
      color: var(--mobees-black);
      filter: drop-shadow(4px 0px 0px rgba(rgb(71, 108, 255), 0)); 
    }
    25%{ 
      color: var(--mobees-black);
      filter: drop-shadow(-4px 0px 4px rgba(rgb(71, 108, 255), 0.5)); 
    }
    50%{ 
      color: rgb(71, 108, 255); 
      filter: drop-shadow(0px 4px 4px rgba(rgb(71, 108, 255), 1)); 
    }
    75%{ 
      color: var(--mobees-black);
      filter: drop-shadow(-4px 0px 4px rgba(rgb(71, 108, 255), 0.5)); 
    }
    100% { 
      color: var(--mobees-black);
      filter: drop-shadow(4px 0px 0px rgba(rgb(71, 108, 255), 0)); 
    }
  }

  .v-app-bar .main-nav {
    max-width: 50%;
    margin-right: 100px;
  }

  .v-app-bar .entities {
    border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .v-app-bar .logo {
    max-height: 26px;
    -webkit-filter: invert(72%);
    filter: invert(72%);
  }

  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title, .v-toolbar__extension > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 8px;
  }

  .v-app-bar .btn-new {
    bottom: -16px;
  }

</style>

<script>
  // Utilities
  import services from '@/services'
  import { sync } from 'vuex-pathify'
  import { Scroll } from 'vuetify/lib/directives';
  import device from 'mobile-device-detect';
  import _ from 'lodash';

  // Icons
  import { 
    mdiHelpCircleOutline, mdiMagnify, mdiRefresh, mdiPlus, mdiChevronLeft, mdiChevronDown, mdiDotsVertical,
    mdiMapMarkerMultipleOutline,
    mdiAccountOutline,
    mdiImageOutline,
    mdiViewDashboard,
  } from '@mdi/js';

  export default {
    name: 'TopBar',

    metaInfo () {
      return {
        title: this.view.title,
      };
    },

    data: () => ({
      icons: {
        mdiHelpCircleOutline, 
        mdiMagnify, mdiRefresh, mdiPlus, mdiChevronLeft, mdiChevronDown, mdiDotsVertical,
        buzzers: mdiMapMarkerMultipleOutline,
        dashboard: mdiViewDashboard,
      },
      view: {
        title: '',
        history: 0,
        scroll: 0,
        display: true,
        mobile: false
      },
      logos: {},
      avatar: '',
      fab: {
        toggle: false,
        text: '',
        to: null
      },
      bar: {
        timeout: null,
      }
    }),

    components: {
      IconBase: () => import('@/components/IconBase'),
      BrandSymbol: () => import('@/components/icons/BrandSymbol'),
      // BrandLogo: () => import('@/components/icons/BrandLogo'),
    },

    computed: {
      menu: sync('app/views@menu'),
      drawer: sync('app/views@drawer'),

      views: sync('app/views'),
      loading: sync('app/views@loading'),
      main: sync('app/views@main'),
      filters: sync('dashboard/data@filters'),
      user: sync('user/data'),
      redirect: sync('app/redirect'),
      toast: sync('app/toast'),


      minScroll () {
        return this.view.scroll < 24;
      },

      hasLogo () {
        return _.has(this.logos, this.filters.brand);
      },

      setLogo () {
        return this.hasLogo ? this.logos[this.filters.brand] : '';
      },

      setTitle () {
        return this.user.username;
      }

      // isLoading () {
      //   const content = this.$route.path.split('/')[1];
      //   return _.filter(this.loading.resources, (r) => {
      //     console.log(r, content, r.indexOf(content))
      //     return r.indexOf(content) >= 0;
      //   }).length > 0;
      // },
      
    },

    watch: {
      $route (to, from) {
        this.updateView(to);
      }
    },

    methods: {
      ...services,

      // getTitle (route) {
      //   const path = route.path.split('/');
      //   let content = path[1];
      //   let sub;

      //   switch (content) {
      //     case 'campaigns':
      //       const campaign = path.length >= 3 ? path[2] : null;
      //       const ad = path.length == 5 ? path[4] : null;

      //       if (ad) {
      //         if (ad=='new') {
      //           sub = 'Novo Anúncio';
      //         }else{
      //           sub = this[content][campaign].ads[ad].title;
      //         }
      //         this.toggleFab();
      //       }else if (campaign) {
      //         if (campaign=='new') {
      //           sub = 'Nova Campanha';
      //           this.toggleFab();
      //         }else{
      //           if (route.path.indexOf('/ads')>=0){
      //             this.toggleFab({
      //               toggle: true, 
      //               text: 'Novo Anúncio', 
      //               to: route.path + '/new'
      //             });
      //           }else{
      //             this.toggleFab();

      //           }
      //           sub = this[content][campaign].title;
      //         }
      //       }else{
      //         this.toggleFab({
      //           toggle: true, 
      //           text: 'Nova Campanha', 
      //           to: '/campaigns/new'
      //         });
              
      //         sub = null;
      //       }
      //       break;
            
      //     case 'drivers':
      //       const driver = path.length == 3 ? path[2] : null;
      //       if (driver && _.has(this[content], driver)){
      //         const name = this[content][driver].fullname.split(' ');
      //         sub = name[0] + ' ' + name[name.length-1];
      //       }else{
      //         sub = null;
      //       }
      //       this.toggleFab();
      //       break;

      //     case 'buzzers':
      //       const buzzer = path.length == 3 ? path[2] : null;
      //       if (buzzer && _.has(this[content], buzzer)){
      //         sub = this.formatBuzzerCode(buzzer);
      //       }else{
      //         sub = null;
      //       }
      //       this.toggleFab();
      //       break;

      //     default:
      //       this.toggleFab();
      //       sub = null;
      //       break;
      //   }
      //   if (sub) {
      //     this.view.title = sub;
      //   }else{
      //     if (content == '') content = 'dashboard';
      //     this.view.title = this.views.drawer.items[content].text;
      //   }
      // },

      updateView (route) {
        // this.getTitle(route);
        // if (_.isNil(this.logos[this.filters.brand])) {
        //   this.getLogo(this.filters.brand);
        // }
      },

      toggleFab (data) {
        data = this.isValid(data) ? data : {
          toggle: false,
          text: '',
          to: null
        };
        this.fab = Object.assign({}, this.fab, data);
      },

      onScroll (e) {
        this.view.scroll = e.target.scrollingElement.scrollTop;
      },

      navigateBack () {
        let path = this.$route.path.split('/');
        path.pop();
        if (_.indexOf(path, 'campaigns')>=0 && path.length==3) path.pop();
        this.$router.push(path.join('/'));
      },

      toggleMenu (b) {
        this.drawer.toggle = b;
      },

      switchWindow (target) {
        this.$router.push('/' + target.value);
      },

      getLogo () {
        this.toggleLoading(true,'app.getLogo');

        const username = this.user.username;
        const token = this.user.auth.token;
        const brand = this.filters.brand;

        this.$api(
          {
          url: '/getmarcaadmin/'+username+'/'+token+'/'+brand,
            method: 'GET',
          })
          .then(response => {
            console.log('getLogo => ', response);
            if(response.status==200){
              // store logo
              let media = 'data:image/png;base64,' + response.data.data.midia_logomarca;
              this.$set(this.logos, this.filters.brand, media);

            }else if(response.data.retorno==401){
              this.getout();
              this.handleError(response.data.retorno, 'Sua sessão expirou...');
            }else{
              this.handleError(response.data.retorno, 'Erro desconhecido. Vamos investigar o que houve! 😊');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.toggleLoading(false,'app.getLogo');
          });
      }
    },

    mounted () {
      this.updateView(this.$route);
      window.addEventListener('scroll', this.onScroll);
      this.view.mobile = device.isMobileOnly;
    },
    destroyed () {
      window.removeEventListener('scroll', this.onScroll);
    },
  }
</script>
